export enum ErrorCode {
    // General error codes.
    None = 0,
    InvalidGuidFormat = 1,
    InvalidGdidFormat = 2,
    NullValue = 3,
    NotEqual = 4,
    InvalidValue = 5,
    SpecialCharactersNotAllowed = 6,
    InvalidDateRange = 7,
    DateRangeExceeded = 8,
    NoFileFound = 9,
    InvalidFileType = 10,
    ConflictingDateSelection = 11,

    // Product error codes.
    InvalidProductId = 100,
    InvalidProductName = 101,
    ProductNameAlreadyExists = 102,
    InvalidProductColourFormat = 103,
    ProductDoesNotExist = 104,
    ProductHasModels = 105,
    ProductHasParameters = 106,
    ProductHasCalculations = 107,
    ProductHasAppliances = 108,
    ProductDoesNotHaveModels = 109,
    ModelDoesNotExist = 110,

    // Firmware error codes.
    InvalidDeviceId = 200,
    InvalidDeviceType = 201,
    InvalidDeviceSubType = 202,
    InvalidReleaseGroupId = 203,
    InvalidReleaseGroupName = 204,
    ReleaseGroupNameAlreadyExists = 205,
    ReleaseGroupDoesNotExist = 207,
    DeviceAlreadyInReleaseGroup = 208,
    DeviceNotInReleaseGroup = 209,
    InvalidFirmwareId = 210,
    InvalidReleaseHistoryId = 211,
    DeviceDoesNotExist = 212,
    NoFirmwareForDeviceType = 213,
    FirmwareDoesNotExist = 214,
    FirmwareAlreadyExists = 215,
    DefaultSeriesIdentifierRequired = 216,
    NoProductModel = 217,
    InvalidReleaseDate = 218,
    GeneralReleaseAlreadyPushed = 219,
    InvalidMinAppVersion = 220,
    InvalidSeriesIdentifers = 221,

    // Firmware Upload error codes.
    ZipFileHasFolders = 231,
    NoReleaseConfig = 232,
    NoFileWithName = 233,
    NoDataInJson = 234,
    IncorrectFileCount = 235,
    FileDoesNotMatchFormat = 236,
    FileDoesNotHaveExtension = 237,
    FilehasNoModule = 238,
    SeqIdInvalidOrNull = 239,
    DuplicateSeqId = 240,
    DuplicateFileName = 241,
    FileHasNoVersion = 242,
    FileHasInvalidVersion = 243,
    NoMatchingFileFound = 244,
    TestBuildNotAllowed = 245,
    EmptyFileNotAllowed = 246,
    FileAlreadyInStorage = 247,
    InvalidFileName = 248,
    InvalidReleaseConfig = 249,

    // User error codes
    InvalidUserId = 300,
    UserDoesNotExist = 301,
    InvalidEmailFormat = 302,
    UserEmailAlreadyInUse = 303,
    UserAlreadyBlocked = 304,
    UserIsNotConsumer = 305,
    GroupNameAlreadyExists = 314,
    GroupDoesNotExist = 315,
    PermissionDoesNotExist = 316,
    CannotCreateOrAmendCoreGroup = 317,
    InvalidAnnouncementCommunicationSource = 320,
    InvalidAnnnouncementType = 321,
    InvalidGroupId = 322,
    InvalidGroup = 323,
    NewUserGroupRequired = 324,
    InvalidExtensionKey = 325,
    ExtensionKeyLength = 326,
    NullExtensionValue = 327,
    ExtensionValueLength = 328,
    ExtensionDescriptionLength = 329,
    InvalidPermissions = 330,
    InvalidUserName = 331,
    DoesNotHavePermission = 332,
    InvalidGroupType = 333,
    ProductServiceAlredyExist = 334,

    // Hub Error Codes
    ApplianceNotLinkedToHub = 400,
    HubDoesNotExist = 401,
    InvalidHubId = 402,
    InvalidHubType = 403,

    // Alarm Error Codes
    InvalidAlarmId = 500,
    AlarmDoesNotExist = 501,
    AlarmCompleted = 502,
    InvalidAlarmType = 503,
    InvalidAlarmStatus = 504,

    // Notification Error Codes
    InvalidNotification = 601,
    InvalidRecipient = 602,
    NoRecipients = 603,
    NoSender = 603,
    NoTitle = 604,
    AnnouncementDoesNotExist,

    // Dsm Error Codes
    InvalidDsmProviderId = 700,
    DsmDoesNotExist = 701,
    NoHubIdsProvided = 702,
    UnexpectedError = 703,
    NoAccess = 704,

    // Crypto Error Codes
    InvalidSerialNumber = 800,
    DeviceCertNotFound = 801,
}